import React from 'react';
import './Header.css'

export const Header = () => {
    return (
        <div className="headerApi">
            <h1>API EL CHAVO | REACT</h1>
        </div>
    )
}
